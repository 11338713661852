jQuery(function ($) {

  $(window).on('scroll', function () {
    if ($(window).scrollTop() > $('.is-first-box').height()) {
      $('header').addClass('is-scroll');
    } else {
      $('header').removeClass('is-scroll');
    }
  });

  if (!navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)) {
    $('.is-tellink').addClass('is-nonelink');
  }

  $('.is-open-btn').click(function () {
    $(this).toggleClass('is-active');
    $(this).nextAll('ul').toggleClass('is-active');
  });

  $('a[href^="#"]').click(function() {
    let speed = 500;
    let href = $(this).attr("href");
    let target = $(href == "#" || href == "" ? 'html' : href);
    let position = target.offset().top;
    $('html').animate({scrollTop:position}, speed, 'swing');
    return false;
  });

  $('.l-hamburger-btn').click(function () {
    $('.l-hamburger-btn').toggleClass('is-active');
    $('.l-hamburger-btn__bar').toggleClass('is-active');
    $('.l-hamburger-menu').toggleClass('is-active');
    $('body').toggleClass('is-static');
  });

});
